import React from 'react';
import './App.css';
import MainRouting from './navigation/mainRouting';

function App() {
  
  return (
    <div className="App">
        <MainRouting/>
    </div>
  );
}

export default App;
